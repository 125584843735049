<template>
    <div class="UserAnaly_B">
        <div class="Analy_B_box">
            <div class="analy_B_title">
                <span>确定目标客户</span>
            </div>
            <div class="rana_B_title">
                * 除选定的目标客户外还需要填写两类目标客户
            </div>
            <img class="_B_img_line" src="./../../../assets/img/line_1.png" alt="">
            <div class="_B_div_con">
                <ul class="_B_ul_kh">
                    <li v-for="(item,index) in caseList" :key="index">
                        <div class="_B_div_one">
                            <div class="_one_img">
                                <span>目标用户</span>
                            </div>
                            <div class="_B_subject" v-if="index==0">
                                <span>{{item.name}}</span>
                            </div>
                            <div class="_B_subject" v-else>
                                <el-select v-model="item.name" placeholder="请选择目标用户" size="small">
                                    <el-option
                                    v-for="items in options"
                                    :key="items.id"
                                    :label="items.role"
                                    :value="items.role">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="_B_div_one">
                            <div class="_one_img">
                                <span>主语</span>
                            </div>
                            <div class="_B_subject" v-if="index==0">
                                <span>{{item.role}}</span>
                            </div>
                            <div class="_B_subject _li_color" v-else>
                                <el-input v-model="item.role" placeholder="请输入主语" maxlength="12"></el-input>
                            </div>
                        </div>
                        <div class="_B_vation_two">
                            <div class="_one_img">
                                <span>购买动机</span>
                            </div>
                            
                            <div class="_B_motivation" v-if="index == 0">
                                <span :title="item.gmdj">{{item.gmdj}}</span>
                            </div>
                            <div class="_B_motivation" v-else>
                                <el-input type="textarea" maxlength="40" v-model="item.gmdj" placeholder="请输入购买动机"></el-input>
                            </div>
                        </div>
                        <div class="_B_vation_two _B_div_three">
                            <div class="_one_img">
                                <span>购买行为</span>
                            </div>
                            <div class="_B_motivation" v-if="index == 0">
                                <span :title="item.gmxw">{{item.gmxw}}</span>
                            </div>
                            <div class="_B_motivation" v-else>
                                <el-input type="textarea" maxlength="40" v-model="item.gmxw" placeholder="请输入购买行为"></el-input>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 按钮 -->
            <div class="_B_butt_div">
                <div class="_B_button_one" v-preventReClick @click="prevstep()">
                    <span>上一步</span>
                </div>
                <div class="_B_button_two" v-preventReClick @click="nextBotn()">
                    <span>下一步</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            caseList:[],
            options:[]
        }
    },
    methods: {
        getapp(){
            this.axios.projectS2P2({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    var str = res.data.data.qrcode
                    this.$emit('info', str);
                    // var arr = []
                    // res.data.data.values.forEach(item=>{
                    //     arr.push(
                    //         Object.assign({},item,{inputValue:'',inputVisible:false})
                    //     )
                    // })
                    this.caseList = res.data.data.values
                    this.options = res.data.data.select
                }else{
                    this.caseList = [] 
                }
            }).catch(err=>{

            })
        },
        prevstep(){
            this.$router.push({name:'CustorAnaly_A'})
        },
        nextBotn(){
            this.axios.Sets2({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
                node:'p2',
                value:this.caseList,
            }).then(res=>{
                if(res.data.code==0){
                    this.$router.push({name:'CustorAnaly_C'})
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/student/A/UserAnaly_B.css';
</style>
<style>
._B_subject .el-select .el-input__inner{
    /* border: 1px solid #DCDFE6; */
    border: none;
}
._B_subject .el-input__inner{
    padding:0;
    background: transparent;
    border: none;
    color: #fff;
    text-align: center;
}
._B_motivation .el-textarea{
    width: 100%;
    height: 100%;
}
._B_motivation .el-textarea__inner{
    width: 100%;
    height: 100%;
    line-height: 1.5;
    padding:0;
    background: transparent;
    border: none;
    resize: none;
    color: #fff;
    text-align: justify;
    text-justify: distribute;
    word-break:break-all;
    display:-webkit-box;/**对象作为伸缩盒子模型展示**/
    -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp:3;/**显示的行数**/
    overflow:hidden;/**隐藏超出的内容**/
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}
</style>